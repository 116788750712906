import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeroImage from "../images/mini-hd-walk-behind-header-500h.jpg"
import ProductLayout from "../components/ProductLayout"
import { Link } from "gatsby"
import MicroMagImage from "../components/individualProductPage/images/micromag-20d-pa-360s-view-1.jpg"
import MicroMiniImage from "../components/individualProductPage/images/micromini-20d-360-view-1.jpg"
import MicroHDImage from "../components/individualProductPage/images/micro-hd-25c-floor-360s-view-1.jpg"
import MiniHDImage from "../images/mini-hd-25c_0001_mini-hd-25c-1.6.png"
import MagHDImage from "../components/individualProductPage/images/mag-hd-29c-360-view-1.jpg"
import './walk-behind-floor-scrubbers.scss'

const WalkBehindFloorScrubbers = () => {
  return (
    <>
      <SEO title="Walk Behind Floor Scrubbers" />
      <Layout>
          <div className="hero-container">
            <img src={HeroImage} alt="" />
          </div>
          <div className="product-layout" id="walk-behind-floor-scrubber-products">
            <ProductLayout
              image={MicroMagImage}
              title="MicroMag"
              linkName="Download MicroMag Brochure"
              imageLink="/walk-behind-floor-scrubbers/micromag"
              link="https://www.factorycat.com/docs/brochures/FC-MICROMAG-BROCHURE.pdf"
              content="Rugged, little, and easy to store, this battery-powered micro scrubber cleans your floors in congested areas more efficiently and faster than manual cleaning with a mop, bucket, and ringer.   Offered in traction and brush drive this machine can accommodate all your small cleaning tasks.  Available in 17” (43cm)  - 20” (51cm) scrub width. "
              navigate="/walk-behind-floor-scrubbers/micromag"
              subTitle="Battery Walk Behind Micro Floor Scrubber"
            />
            <ProductLayout
              image={MicroMiniImage}
              title="MicroMini"
              linkName="Download MicroMini Brochure"
              link="https://www.factorycat.com/docs/brochures/FC-MICROMINI-BROCHURE.pdf"
              content="Nimble and tough, this durable micro scrubber is battery powered, takes on small spaces, tight areas, hard to reach places and still clean larger rooms quickly.  Clean around obstacles with ease and increase your efficiency over cleaning by hand.  Available in 20”(51cm) - 26”(66cm) scrub width.   "
              navigate="/walk-behind-floor-scrubbers/micromini"
              imageLink="/walk-behind-floor-scrubbers/micromini"
              subTitle="Battery Walk Behind Micro Floor Scrubber"
            />

            <ProductLayout
              image={MicroHDImage}
              title="Micro-HD"
              linkName="Download Micro-HD Brochure"
              link="/FC-V2-WALK-BEHIND-SM.pdf"
              content="Agile, quiet, and powerful, this compact floor scrubber is ready to take on smaller spaces with any floor type. Rubberized floors, grouted tile, exposed aggregate and concrete floors are no match.  Select our SUDS on board soap dilution system to better control your cleaning chemical costs. Available in 20”(51cm) - 26”(66cm) scrub width."
              navigate="/walk-behind-floor-scrubbers/micro-hd"
              imageLink="/walk-behind-floor-scrubbers/micro-hd"
              subTitle="Battery Walk Behind Compact Floor Scrubber"
            />

            <ProductLayout
              image={MiniHDImage}
              title="Mini-HD"
              linkName="Download Mini-HD Brochure"
              link="/FC-V2-WALK-BEHIND-SM.pdf"
              content="With the perfect balance of maneuverability and productivity, the Mini-HD will simplify and improve your cleaning. This mid-sized walk-behind floor scrubber offers battery pack options, available ZerO3 On-Demand Aqueous Ozone chemical free cleaning system, and selectable tires. This floor scrubber can be customized to fulfill your needs.  Available in 20”(51cm) -  29”(74cm) scrub width."
              navigate="/walk-behind-floor-scrubbers/mini-hd"
              imageLink="/walk-behind-floor-scrubbers/mini-hd"
              subTitle="Battery Walk Behind Floor Scrubber"
            />
  
            <ProductLayout
              image={MagHDImage}
              title="Mag-HD"
              linkName="Download Mag-HD Brochure"
              link="/FC-V2-WALK-BEHIND-SM.pdf"
              content="For large areas not big enough for a ride-on floor scrubber, but where maximum output is required from a walk behind floor scrubber, the Mag-HD is the clear winner. Offering exceptional run time that allows you to scrub a long time on a single charge. Multiple scrub deck options generate high operator productivity.   Include an optional Spray Jet spray wand to increase the cleaning capabilities beyond that of a typical scrubber. Available in 28”(71cm) -  34”(86cm) scrub width."
              navigate="/walk-behind-floor-scrubbers/mag-hd"
              imageLink="/walk-behind-floor-scrubbers/mag-hd"
              subTitle="Battery Walk Behind Large Floor Scrubber"
            />
          </div>
      </Layout>
    </>
  )
}

export default WalkBehindFloorScrubbers
