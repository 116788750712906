import React from "react"
import { Link } from "gatsby"
import './productlayout.scss'

const ProductLayout = props => {
  return (
      <div className="container">
        <div className="img-container">
          <h3>{props.title}</h3>
            <img src={props.image} alt={props.alt} />
            <a className="brochure-download-link" target="_blank" rel="noreferrer" href={props.link}>
            {props.linkName} <i className="fas fa-download" />
          </a>
          <h4>{props.subTitle}</h4>
          <p>{props.content}</p>
        </div>

        <Link to={props.navigate}>
            <button>Learn More</button>
          </Link>
      </div>
  )
}

export default ProductLayout
